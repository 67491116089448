export const Textarea = ({
  register,
  errors,
  rows,
  name,
  className,
  topDivCss,
  label,
  labelstyle,
  placeholder,
  labelCss,
}) => (
  <div className={topDivCss || ''}>
    {label && (
      <label htmlFor={name || ''} style={labelstyle} className={labelCss}>
        {label}
      </label>
    )}
    <textarea
      rows={rows || ''}
      className={className || ''}
      placeholder={placeholder || ''}
      {...register(name)}
      name={name || ''}
    ></textarea>
    {errors.description && (
      <span className="text-sm text-red-700">{errors.description.message}</span>
    )}
  </div>
);

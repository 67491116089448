import { yupResolver } from '@hookform/resolvers/yup';
import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import * as yup from 'yup';
import dynamic from 'next/dynamic';

import { ALL_SUBMIT_FORM } from 'app/helpers/apiEndpoint';
import { getTrackingData } from 'app/webtrack/tracking';
import REQUEST from 'app/helpers/http.service';
import { timeZoneCityToCountry } from 'app/helpers/timeZoneCityToCountry';
import { validatePhoneNumber } from 'app/scripts/validatePhoneNumber';
import {
  subscribeEmail,
  getUserLocationData,
  getLocaleIP,
} from 'app/scripts/utils';

import Container from '../common/Container';
import CloudinaryImage from '../common/CloudinaryImage';
import { TextInput } from '../FormComponent/TextInput';
import { Textarea } from '../FormComponent/Textarea';
import FormButton from '../common/FormButton';
import { PhoneNumberInput } from '../FormComponent/PhoneNumberInput';
const Captcha = dynamic(() => import('../common/Captcha'), {
  ssr: false,
});

export default function WriteGetInTouch() {
  const router = useRouter();
  const [captchaError, setCaptchaError] = useState(false);
  const [locationip, setLocationIP] = useState(false);
  const [captchaVerified, setCaptchaVerified] = useState(false);
  const [phone, setPhone] = useState('');
  const [phoneError, setPhoneError] = useState('');

  const locationData = getUserLocationData(timeZoneCityToCountry);

  useEffect(() => {
    const fetchIP = async () => {
      const { ip } = await getLocaleIP();
      setLocationIP(ip);
    };
    fetchIP();
  }, []);

  const handleCaptchaVerify = isValid => {
    setCaptchaVerified(isValid);
  };

  // .matches(
  //   /^(?!.*@(?:gmail|outlook|yahoo|protonmail|zoho|aol)\.\w{2,}$)[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
  //   'Work email addresses are allowed'
  // ),
  const Schema = yup.object().shape({
    email: yup
      .string()
      .email('Invalid email format')
      .required('Email is required'),
    name: yup
      .string()
      .required('Name is required')
      .matches(
        /^[a-zA-Z\s]*$/,
        'Name can not contain number and special character'
      ),
    organization: yup
      .string()
      .required('Organization is required')
      .matches(/^[a-zA-Z\s]*$/, 'Organization Name can not contain number'),
    description: yup.string().required('Message is required'),
  });

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    resolver: yupResolver(Schema),
    defaultValues: {
      name: '',
      email: '',
      description: '',
      organization: '',
      pageUrl: router.asPath,
      countryName: locationData.userCountry,
      ctaText: 'Submit',
      leadSource: 'Get In Touch!',
      purpose: 'LEADS',
    },
  });
  // LEADS
  const toast = useSelector(state => state?.toast);

  const submitHandler = async data => {
    const validationError = validatePhoneNumber(phone);
    if (!validationError) {
      if (!captchaVerified) {
        setCaptchaError(true);
      } else {
        try {
          const trackingData = getTrackingData();

          // Ensure WebPagesVisited is a proper string
          const cleanedTrackingData = {
            ...trackingData,
            WebPagesVisited: Array.isArray(trackingData?.WebPagesVisited)
              ? trackingData.WebPagesVisited.join(', ') // Convert array to comma-separated string
              : trackingData?.WebPagesVisited || '', // Ensure it's a string
          };

          const formData = {
            ...data,
            phoneNumber: phone,
            ipAddress: locationip,
            trackingData: cleanedTrackingData, // Use cleaned tracking data
          };

          const res = await REQUEST({
            method: 'POST',
            url: ALL_SUBMIT_FORM,
            data: { data: formData },
          });

          if (res?.status === 200) {
            reset();
            setPhone('');
            subscribeEmail(data);
            router.push('/thankyou');
          } else toast.error(res?.data?.error?.message);
        } catch (err) {
          toast.error('failed');
        }
      }
    } else {
      setPhoneError(validationError);
    }
  };
  // for showing blog in web only
  const [windowWidth, setWindowWidth] = useState(0);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <div id="Getintouchform" className="bg-[#F0F0F0]">
      <Container>
        <div className="sm:grid xl:grid-cols-12 gap-10 xl:py-[75px] py-[35px]">
          <div className="lg:col-span-5">
            <h6 className="xl:text-[45px] md:text-[32px] text-[26px] font-[800] text-[#000000] leading-tight mb-4">
              Book a Free Consultation Call with Our Experts Today
            </h6>
            {windowWidth > 768 && (
              <CloudinaryImage
                backendImgUrl={
                  'https://res.cloudinary.com/dthpnue1d/image/upload/v1735014024/4655332_2480265_d06f0fc3ac.webp'
                }
                alt={'GetInTouch'}
                type="smallimg"
              />
            )}
          </div>

          <div className="lg:col-span-7">
            <form
              className="flex flex-col"
              onSubmit={handleSubmit(submitHandler)}
              autoComplete="off"
            >
              <div className="md:flex">
                <TextInput
                  label="Name*"
                  register={register}
                  name="name"
                  errors={errors}
                  topDivCss={'md:w-1/2 px-[10px] relative'}
                  className="bg-[#fff] border-[1px] border-[#d8d8e2] p-[8px_8px] h-[45px] w-[100%] block text-[16px] outline-0 focus:border-[#d8d8e2] focus:ring-0 outline-[transparent_!important] text-[#191A59]"
                  labelCss={
                    'text-[16px] font-[400] mb-[6px] text-[#383838] block'
                  }
                />

                <TextInput
                  label="Work Email*"
                  register={register}
                  name="email"
                  errors={errors}
                  type="email"
                  topDivCss="md:w-1/2 px-[10px] mt-4 md:mt-0 relative"
                  className={
                    'bg-[#fff] border-[1px] border-[#d8d8e2] p-[8px_8px] h-[45px] w-[100%] block text-[16px] outline-0 focus:border-[#d8d8e2] focus:ring-0 outline-[transparent_!important] text-[#191A59]'
                  }
                  labelCss={
                    'text-[16px] font-[400] mb-[6px] text-[#383838] block'
                  }
                />
              </div>

              <div className="md:flex mt-3 md:mt-10 justify-items-stretch relative">
                <PhoneNumberInput
                  phone={phone}
                  setPhone={setPhone}
                  phoneError={phoneError}
                  setPhoneError={setPhoneError}
                  validatePhoneNumber={validatePhoneNumber}
                  topDivCss="md:w-1/2 px-[10px]"
                  name="phoneNumber"
                  label="Phone Number*"
                  labelCss={
                    'text-[16px] font-[400] mb-[6px] text-[#383838] block'
                  }
                  phoneclassName="phoneNumberNobor p-[0px_!important] phonenumber react-tel-input bg-[#fff] border-[1px] border-[#d8d8e2] h-[45px] w-[100%] block text-[16px] outline-0 focus:border-[#d8d8e2] focus:ring-0 outline-[transparent_!important] text-[#191A59]"
                />

                <TextInput
                  topDivCss="md:w-1/2 px-[10px] mt-4 md:mt-0 relative"
                  label="Organization Name*"
                  register={register}
                  name="organization"
                  errors={errors}
                  type="text"
                  className={
                    'bg-[#fff] border-[1px] border-[#d8d8e2] p-[8px_8px] h-[45px] w-[100%] block text-[16px] outline-0 focus:border-[#d8d8e2] focus:ring-0 outline-[transparent_!important] text-[#191A59]'
                  }
                  labelCss={
                    'text-[16px] font-[400] mb-[6px] text-[#383838] block'
                  }
                />
              </div>

              <Textarea
                rows="4"
                label="Small brief about Project*"
                register={register}
                name="description"
                errors={errors}
                placeholder="Please describe your project requirements"
                topDivCss="md:mt-10 mt-3 relative px-[10px]"
                labelCss={
                  'text-[16px] font-[400] mb-[6px] text-[#383838] block'
                }
                className="bg-[#fff] border-[#D8D8E2] placeholder:text-[#000000] text-[16px] w-[100%] block focus:ring-0 focus:border-[rgba(146,146,146,0.5)] focus-visible:outline-0 text-[#000]"
              />

              <div className="mt-10">
                <div className="mt-2 md:mb-12 mb-[40px] px-[10px]">
                  <Captcha
                    onVerify={handleCaptchaVerify}
                    captchaError={captchaError}
                    textColor={'#000000'}
                    bgColor={'#ffffff'}
                  />
                </div>
                <FormButton
                  id={'Submit'}
                  type="submit"
                  text="Talk to our experts"
                  btnclassName="btnleftright relative overflow-hidden bg-[#0050D5] text-[#ffffff] border border-[#fff] group relative rounded text-center lg:px-12 lg:py-2 px-10 py-3 shadow-[10px_20px_40px_0px_#00000033]"
                  spanclassName="relative z-[5] transition duration-300 ease-in-out"
                  divclassName="md:block px-[10px]"
                />
              </div>
            </form>
          </div>
        </div>
      </Container>
    </div>
  );
}

export default function Email() {
  return (
    <div className="absolute left-[10px_!important] inset-y-0 start-0 flex items-center  pointer-events-none w-[18px]">
      <svg
        width="1.1em"
        height="1.1em"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M1.75 3H22.25C23.216 3 24 3.784 24 4.75V18.75C24 19.2141 23.8156 19.6592 23.4874 19.9874C23.1592 20.3156 22.7141 20.5 22.25 20.5H1.75C1.28587 20.5 0.840752 20.3156 0.512563 19.9874C0.184374 19.6592 0 19.2141 0 18.75L0 4.75C0 3.784 0.784 3 1.75 3ZM1.5 7.412V18.75C1.5 18.888 1.612 19 1.75 19H22.25C22.3163 19 22.3799 18.9737 22.4268 18.9268C22.4737 18.8799 22.5 18.8163 22.5 18.75V7.412L12.98 13.845C12.388 14.245 11.612 14.245 11.02 13.845L1.5 7.412ZM1.5 4.75V5.602L11.86 12.602C11.9013 12.6299 11.9501 12.6449 12 12.6449C12.0499 12.6449 12.0987 12.6299 12.14 12.602L22.5 5.602V4.75C22.5 4.6837 22.4737 4.62011 22.4268 4.57322C22.3799 4.52634 22.3163 4.5 22.25 4.5H1.75C1.6837 4.5 1.62011 4.52634 1.57322 4.57322C1.52634 4.62011 1.5 4.6837 1.5 4.75Z"
          fill="#0050D5"
        />
      </svg>
    </div>
  );
}

export const validatePhoneNumber = value => {
  if (!value || value.trim().length === 0) return 'Phone number is required';
  if (value.length < 5) return 'Phone number must be at least 5 digits';
  if (!/^\+?[0-9\s\-().]+$/.test(value)) return 'Invalid phone number format';

  const numericValue = value.replace(/\D/g, '');

  // Check for 4 repeating digits in a row
  if (/(\d)\1{3}/.test(numericValue)) {
    return 'Phone number cannot contain 4 repeating digits in a row';
  }

  // Check for sequential numbers (ascending or descending)
  const SEQUENCES = [
    '0123456789',
    '1234567890',
    '2345678901',
    '3456789012',
    '4567890123',
    '5678901234',
    '6789012345',
    '7890123456',
    '8901234567',
    '9012345678',
    '9876543210',
    '8765432109',
    '7654321098',
    '6543210987',
    '5432109876',
    '4321098765',
    '3210987654',
    '2109876543',
  ];

  if (SEQUENCES.some(seq => numericValue.includes(seq))) {
    return 'Phone number cannot be a sequential number';
  }

  return '';
};

export default function FormButton({
  btnclassName,
  spanclassName,
  type,
  id,
  text,
  divclassName,
}) {
  return (
    <div className={divclassName || ''}>
      <button className={btnclassName || ''} type={type || ''} id={id || ''}>
        <span className={spanclassName || ''}>{text || ''}</span>
      </button>
    </div>
  );
}

import Email from '../SvgIcon/Email';
import User from '../SvgIcon/User';

export const TextInput = ({
  label,
  register,
  name,
  errors,
  type = 'text',
  style,
  className,
  topDivCss,
  labelstyle,
  placeholder,
  icon,
  labelCss,
}) => (
  <div className={topDivCss || ''}>
    {/* style={labelstyle || ''} */}
    {label && (
      <label htmlFor={name || ''} className={labelCss}>
        {label}
      </label>
    )}

    {icon ? (
      <div className="relative">
        {icon === 'user' && <User />}
        {icon === 'email' && <Email />}
        <input
          type={type || ''}
          className={className || ''}
          placeholder={`${placeholder || ''}`}
          {...register(name)}
          // style={style || ''}
          maxLength={'30'}
        />
      </div>
    ) : (
      <input
        type={type || ''}
        className={className || ''}
        placeholder={`${placeholder || ''}`}
        {...register(name)}
        // style={style || ''}
        maxLength={'30'}
      />
    )}

    {errors[name] && (
      <span className="text-sm text-red-700">{errors[name]?.message}</span>
    )}
  </div>
);
